import { Controller } from "stimulus"
import {MDCChip} from '@material/chips';

export default class extends Controller {
  static targets = [ "categories" ]

  connect() {
    this.element.querySelectorAll(".form_categories").forEach((categoriesNode) => {
      this.chipsToInputs(null, categoriesNode);
    });

    const chipElements = this.element.querySelectorAll('.category-chip');

    chipElements.forEach((chipEl) => {   
      const chip = new MDCChip(chipEl);   
      chip.listen('MDCChip:interaction', () => {

        if (!chip.selected && (this.getCategories(this.getMainNodeFrom(chip)).length == 0)) {
          chip.selected = !chip.selected;
          this.chipsToInputs(chip, chip.root);
        }

        let selected_size = this.getCategories(this.getMainNodeFrom(chip)).length;
        let limit = this.element.dataset.limit;

        if(chip.selected || (!chip.selected && (selected_size <= limit))) {
          chip.selected = !chip.selected;

          this.chipsToInputs(chip, chip.root);
        }

      });  
    });
  }

  getMainNodeFrom(chip) {
    if (chip != undefined && chip != null) {
      if (chip.root.closest(".subtype") != null)
        return chip.root.closest(".subtype")
      else
        return chip.root.closest(".form-container")
    } else
      return this.element.querySelectorAll(".form-container")
  }

  chipsToInputs(chip, node=null) {
    let _this = this;
    let categories = node.closest(".subtype") == null ? _this.getCategories(node.closest(".form-container")) : _this.getCategories(node.closest(".subtype"));


    if (categories.length > 0 || chip != null) {
      if (node.closest(".subtype") != null)
        node.closest(".subtype").querySelector(".form_categories").innerHTML = "";
      else
        node.closest(".form-container").querySelector(".form_categories").innerHTML = "";
    }

    categories.forEach(( el ) => {
      if (node.closest(".subtype") != null)
        _this.categoryHTML(el.getAttribute("data-category"), node.closest(".subtype"));
      else
        _this.categoryHTML(el.getAttribute("data-category"), node.closest(".form-container"));
    });

    if (chip != undefined && chip != null)
      if (chip.selected){
        _this.categoryHTML(chip.root.dataset.category, this.getMainNodeFrom(chip));
      }
      else {
        this.getMainNodeFrom(chip).querySelector('.form_categories input[value="' + chip.root.dataset.category + '"]').remove();
      }

    this.element.querySelectorAll(".form_categories").forEach((categoriesNode) => {
      if (categoriesNode.innerHTML == 0)
        categoriesNode.innerHTML = "<input type='text' required='required' style='opacity: 0; height: 0; position: absolute;'></div>";
    });

  }

  categoryHTML(value, root) {
    var input = document.createElement("input");
    input.type = "hidden";
    input.value = value;

    if (root.querySelector(".form_categories").getAttribute("data-type") == "interests") {
      input.name = "user[new_categories_ids][]";
    } else if (root.querySelector(".form_categories").getAttribute("data-type") == "geoareas") {
      input.name = "user[new_geoareas_ids][]";
    } else if (root.querySelector(".form_categories").getAttribute("data-type") == "freeform") {
      input.name = "free_form_request["+this.element.dataset.fieldname+"][]";
      input.value = value
    } else {
      input.name = "innovation[new_categories_ids][]";
    }

    root.querySelector(".form_categories").appendChild(input);
  }

  getCategories(node=null) {

    if (node != null)
      return node.querySelectorAll(".categories .mdc-ripple-upgraded--foreground-activation:not(.mdc-chip--selected), .categories .mdc-chip--selected:not(.mdc-ripple-upgraded--foreground-activation)");
    else
      return this.element.querySelectorAll(".categories .mdc-ripple-upgraded--foreground-activation:not(.mdc-chip--selected), .categories .mdc-chip--selected:not(.mdc-ripple-upgraded--foreground-activation)");
  }


  showMoreCategories(event) {
    let chipset = event.currentTarget.closest('.mdc-chip-set');

    chipset.classList.remove('chip-set-limited');
    chipset.querySelector('.btn-more').classList.add('hidden');
    chipset.querySelector('.btn-less').classList.remove('hidden');
  }
  
  showLessCategories(event) {
    let chipset = event.currentTarget.closest('.mdc-chip-set');

    chipset.classList.add('chip-set-limited');
    chipset.querySelector('.btn-more').classList.remove('hidden');
    chipset.querySelector('.btn-less').classList.add('hidden');
  }

}
