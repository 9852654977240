import { Controller } from "stimulus"
import {MDCSelect} from '@material/select';
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "chart", "newForm", "newButton"]

  initialize() {
    let chart = this.chartTarget,
        _this = this;

    chart.addEventListener("click", function(event) {
      let bar = null,
          index = null,
          values = null;

      //edit element
      if (event.target.classList.contains("bar")) {
        bar = event.target;
        index = bar.dataset.index;
        values = JSON.parse(_this.chartTarget.dataset.values);
        values = values.sort((a, b) => a[1] - b[1]);

        const d = new Date();

        //default num/year
        let num = 1,
            year = d.getFullYear().toString();

        if (values.length > 0) {
          num = values[index][0];
          year = values[index][1];
        }

        //set new form inputs to selected bar data
        _this.newFormTarget.querySelector("#number").value = num;

        let selectEl = _this.newFormTarget.querySelector("#year").closest(".mdc-select");

        if (selectEl !== undefined) {
          let select = selectEl.MDCSelect;
          select.value = year;
        }

        _this.showNewForm();
      }

      //delete element
      if (event.target.classList.contains("del")) {
        bar = event.target.closest(".bar");
        index = bar.dataset.index;

        if (index >= 0) {
          values = JSON.parse(_this.chartTarget.dataset.values);
          values = values.sort((a, b) => a[1] - b[1]);
          values.splice(index, 1);

          _this.chartTarget.dataset.values = JSON.stringify(values);
          _this.renderChart();
        }

      }
         
        
    });

    this.renderChart();
  }

  //chart rendering
  renderChart(event) {
    let values = null,
        max = 1,
        chart = null,
        _this = this;

    chart = this.chartTarget;

    values = JSON.parse(this.chartTarget.dataset.values);
    values = values.sort((a, b) => a[1] - b[1]);

    values.forEach((val) => {
      let num = Number(val[0]);

      if (num > max) 
        max = num;
    });

    chart.innerHTML = "";
    if (values.length > 0) {
      values.forEach((val, index) => {
        let height = 100*Number(val[0])/max;
        chart.innerHTML = this.chartTarget.innerHTML + "<div class='bar' style='height: " + height + "%' data-index='" + index + "'><input value='" + val[1] + "'  type='hidden' name='innovation[learners[][year]]' id='innovation_learners[][year]'><input value='" + val[0] + "'  type='hidden' name='innovation[learners[][number]]' id='innovation_learners[][number]'><div class='number'>" + val[0] + "</div><div class='year'>" + val[1] + "</div><div class='mdc-icon-button material-icons del'>clear</div></div>";
      }); 
    } else {
        chart.innerHTML = this.chartTarget.innerHTML + "<div class='bar default' style='height: 30%'></div><div class='bar default' style='height: 60%'></div><div class='bar default' style='height: 100%'></div>";
    }

  }

  //open form for adding new element
  showNewForm(event) {
    this.newButtonTarget.classList.add('hidden');
    this.newFormTarget.classList.remove('hidden');
    this.newFormTarget.querySelector("input#number").focus();
  }

  //adding new element
  addChartItem(event) {
    let num = null,
        year = null,
        values = null;

    num = Number(this.newFormTarget.querySelector("#number").value);
    year = Number(this.newFormTarget.querySelector("#year").value);

    if (num != undefined && year != undefined && Number.isInteger(num) && Number.isInteger(year)) {
      this.newButtonTarget.classList.remove('hidden');
      this.newFormTarget.classList.add('hidden');

      values = JSON.parse(this.chartTarget.dataset.values);

      //remove if element with same year exist
      let existIndex = values.findIndex(item => item[1] === year.toString());
      if (existIndex >= 0)
        values.splice(existIndex, 1);

      //add new element
      values.push([num.toString(), year.toString()]);
      values = values.sort((a, b) => a[1] - b[1]);

      this.chartTarget.dataset.values = JSON.stringify(values);

      this.renderChart();
    }
  }

}
